//inspiration from https://www.youtube.com/watch?v=Jgdx_qykoPw

import React from 'react';

import {useStyles} from "../../styles/MfaVerifyStyles"
import logo from "/static/images/portamedica-logo.png";
import VerifyForm from "../../components/mfa/VerifyForm";


export default function verify() {
    const classes = useStyles();
    return (
        <div className={classes.loginRoot}>
            <img src={logo} className={classes.logoLoginPage} alt="PortaMedica logo"/>
            <VerifyForm/>
        </div>
    );
}
