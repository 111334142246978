import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {navigate} from "gatsby"
import {Card, CardActions, CardContent, Container, TextField} from '@material-ui/core';
import {useUserContext} from '../../context/UserContext';
import Header from "../common/Header";
import BackendError from "../common/BackendError";
import ButtonStyled from "../common/ButtonStyled";

import {useStyles} from "../../styles/MfaVerifyStyles";
import { useTranslation } from 'react-i18next';

interface MfaVerificationData {
    code: string
}

export default function VerifyForm() {
    const classes = useStyles();
    const { t } = useTranslation();

    const {verifyCode, setLoggedInClient} = useUserContext();

    const {register, handleSubmit, formState: {errors}} = useForm<MfaVerificationData>(); //Possible to use useForm<FormData>({mode: "onChange"});
    const [backendError, setBackendError] = useState(null);

    const onSubmit = handleSubmit(({code}) => {
        verifyCode(code).then((res) => {
            if (location.search != "") {
                const params = new URLSearchParams(location.search);
                setLoggedInClient(true);
                navigate(params.get('redirect'));
            } else navigate('/')
        }).catch((error) => {
            if (error.response != undefined) {
                setBackendError(error.response.data.message);
            } else setBackendError(error.message);
        })
    });

    return (
        <Container className={classes.loginContainer}>
            <form onSubmit={onSubmit} noValidate autoComplete="off">
                <Card>
                    <Header title={t("TOTP")} titleTypographyProps={{variant: "h5", align: "center"}}/>
                    {backendError != null ? <BackendError error={backendError}/> : ""}
                    <CardContent>
                        {t("GETCODE")}
                        <TextField
                            fullWidth
                            id="code"
                            type="text"
                            name="code"
                            size="small"
                            label={t("VERIFYCODE")}
                            placeholder="Code"
                            margin="normal"
                            InputLabelProps={{className: (errors.code ? classes.formErrors : null)}}
                            {...register("code", {required: true})}
                        />
                        {errors.code && (<div className={classes.formErrors}>{t("FILLCODE")}</div>)}
                    </CardContent>
                    <CardActions>
                        <ButtonStyled style={{margin: 'auto'}} type="submit" text={t("VERIFY")}/>
                    </CardActions>
                </Card>
            </form>
        </Container>
    );
}
